<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <b-overlay :show="loading">
            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                <b-form  @submit.prevent="handleSubmit(register)" @reset.prevent="reset" >
                    <b-row>
                        <b-col sm="6">
                            <ValidationProvider name="Section" vid="section_id" rules="required|min_value:1">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                            Section <span class="text-danger">*</span>
                                </template>
                                <Select2
                                    v-model="formData.section_id"
                                    :options="sections"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    />
                                <div class="text-danger">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Select Category" vid="category_id" rules="required|min_value:1">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Select Category <span class="text-danger">*</span>
                                </template>
                                <Select2
                                    v-model="formData.category_id"
                                    :options="categoryList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    />
                                <div class="text-danger">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Select Sub Category" vid="quiz_sub_category_id" rules="required|min_value:1">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Select Sub Category <span class="text-danger">*</span>
                                </template>
                                <Select2
                                    id="quiz_sub_category_id"
                                    v-model="formData.quiz_sub_category_id"
                                    :options="quizSubCategoryList"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    />
                                <div class="text-danger">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Title" vid="title" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Title <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="title"
                                    rows="6"
                                    v-model="formData.title"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="12">
                            <h5>Options</h5>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Option (1)" vid="option1" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Option (1) <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="option1"
                                    rows="6"
                                    v-model="formData.options.option1"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Option (2)" vid="option2" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Option (2) <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="option2"
                                    rows="6"
                                    v-model="formData.options.option2"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Option (3)" vid="option3" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Option (3) <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="option3"
                                    rows="6"
                                    v-model="formData.options.option3"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Option (4)" vid="option4" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Option (4) <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="option4"
                                    rows="6"
                                    v-model="formData.options.option4"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Result" vid="result" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Select Result <span class="text-danger">*</span>
                                </template>
                                <Select2
                                :options="optionList"
                                    id="result"
                                    rows="6"
                                    v-model="formData.result"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></Select2>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>
                        <b-col sm="6">
                            <ValidationProvider name="Sort Oder" vid="sort" rules="required">
                                <b-form-group
                                class="row"
                                label-cols-sm="12"
                                label-for="formData"
                                slot-scope="{ valid, errors }"
                                >
                                <template v-slot:label>
                                Sort <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                    id="sort"
                                    rows="6"
                                    v-model="formData.sort"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-input>http://localhost:8081/general-setting/ads
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                                </b-form-group>
                            </ValidationProvider>
                        </b-col>

                    </b-row>
                    <div class="row">
                        <div class="col-sm-3"></div>
                        <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-1')">Close</b-button>
                        </div>
                    </div>
                </b-form>
            </ValidationObserver>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import iziToast from 'izitoast';
import { QUIZ } from '../../Utils/Constant';
export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
      if (this.id) {
          this.formData = this.getItem()
      }
  },
  data () {
    return {
        saveBtnName: this.id ? 'Update' : 'Save',
        formData: {
            title: '',
            category_id: '',
            quiz_sub_category_id: '',
            options: {
                option1: null,
                option2: null,
                option3: null,
                option4: null,
            },
            result: null,
        },
        quizSubCategoryList: [],
        categoryList: []
    }
  },
  watch: {
    'formData.category_id' : function (val) {
        this.getQuizSubCategoryList(val)
    },
    'formData.section_id' : function (val) {
        this.getQuizCategoryList(val)
    }
  },
  computed: {
        sections () {
            const data = this.$store.state.commonObj.sectionList.filter(item => item.type_id == QUIZ)
            data.unshift({ 'id': 0, 'text': 'Select'})
            return data
        },
        optionList () {
        return  [
                {
                    id: 'option1',
                    text: 'Option (1)'
                },
                {
                    id: 'option2',
                    text: 'Option (2)'
                },
                {
                    id: 'option3',
                    text: 'Option (3)'
                },
                {
                    id: 'option4',
                    text: 'Option (4)'
                },
            ]
        },
        loading () {
        return this.$store.state.static.loading
        }
  },
  methods: {
    getQuizSubCategoryList (catId) {
        const data = this.$store.state.commonObj.quizSubCategoryList.filter(item => item.quiz_category_id == catId)
        data.unshift({ 'id': 0, 'text': 'Select'})
        this.quizSubCategoryList = data
    },
    getQuizCategoryList (sectionId) {
        const data = this.$store.state.commonObj.quizCategoryList.filter(item => item.section_id == sectionId)
        data.unshift({ 'id': 0, 'text': 'Select'})
        this.categoryList = data
    },
    getItem () {
        const item = this.$store.state.list.find(item => item.id === parseInt(this.id))
        return JSON.parse(JSON.stringify(item))
    },
    async register () {
          this.$store.dispatch('mutedLoad', { loading: true, listReload: false })
        let result = null
        if (this.id) {
            result = await RestApi.putData(baseUrl, `${'api/quizs/update'}/${this.id}`, this.formData)
        } else {
            result = await RestApi.postData(baseUrl,'api/quizs/store', this.formData)
        }
        this.$store.dispatch('mutedLoad', { loading: false, listReload: true })
        this.$store.dispatch('dropdownLoad', { hasDropdownLoaded: false })
        if (result.success) {
            if (!this.id) {
                this.formData.content = ''
            }
            iziToast.success({
                title: 'Success',
                message: result.message
            })
            //  this.$bvModal.hide('modal-1')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
    }
  }
}
</script>
