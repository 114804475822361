var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('b-row',[_c('b-col',{attrs:{"lg":"12","sm":"12"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('ValidationObserver',{ref:"form",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var reset = ref.reset;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)},"reset":function($event){$event.preventDefault();return reset.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Section","vid":"section_id","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Section "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('Select2',{attrs:{"options":_vm.sections,"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.section_id),callback:function ($$v) {_vm.$set(_vm.formData, "section_id", $$v)},expression:"formData.section_id"}}),_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Select Category","vid":"category_id","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Category "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('Select2',{attrs:{"options":_vm.categoryList,"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.category_id),callback:function ($$v) {_vm.$set(_vm.formData, "category_id", $$v)},expression:"formData.category_id"}}),_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Select Sub Category","vid":"quiz_sub_category_id","rules":"required|min_value:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Sub Category "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('Select2',{attrs:{"id":"quiz_sub_category_id","options":_vm.quizSubCategoryList,"state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.quiz_sub_category_id),callback:function ($$v) {_vm.$set(_vm.formData, "quiz_sub_category_id", $$v)},expression:"formData.quiz_sub_category_id"}}),_c('div',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Title","vid":"title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Title "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"title","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.title),callback:function ($$v) {_vm.$set(_vm.formData, "title", $$v)},expression:"formData.title"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"12"}},[_c('h5',[_vm._v("Options")])]),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Option (1)","vid":"option1","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Option (1) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"option1","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.options.option1),callback:function ($$v) {_vm.$set(_vm.formData.options, "option1", $$v)},expression:"formData.options.option1"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Option (2)","vid":"option2","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Option (2) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"option2","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.options.option2),callback:function ($$v) {_vm.$set(_vm.formData.options, "option2", $$v)},expression:"formData.options.option2"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Option (3)","vid":"option3","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Option (3) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"option3","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.options.option3),callback:function ($$v) {_vm.$set(_vm.formData.options, "option3", $$v)},expression:"formData.options.option3"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Option (4)","vid":"option4","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Option (4) "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"option4","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.options.option4),callback:function ($$v) {_vm.$set(_vm.formData.options, "option4", $$v)},expression:"formData.options.option4"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Result","vid":"result","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Result "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('Select2',{attrs:{"options":_vm.optionList,"id":"result","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.result),callback:function ($$v) {_vm.$set(_vm.formData, "result", $$v)},expression:"formData.result"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1),_c('b-col',{attrs:{"sm":"6"}},[_c('ValidationProvider',{attrs:{"name":"Sort Oder","vid":"sort","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"row",attrs:{"label-cols-sm":"12","label-for":"formData"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Sort "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}],null,true)},[_c('b-form-input',{attrs:{"id":"sort","rows":"6","state":errors[0] ? false : (valid ? true : null)},model:{value:(_vm.formData.sort),callback:function ($$v) {_vm.$set(_vm.formData, "sort", $$v)},expression:"formData.sort"}}),_vm._v("http://localhost:8081/general-setting/ads "),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])],1)}}],null,true)})],1)],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-3"}),_c('div',{staticClass:"col text-right"},[_c('b-button',{staticClass:"mr-2",attrs:{"type":"submit","variant":"primary"}},[_vm._v(_vm._s(_vm.saveBtnName))]),_vm._v("   "),_c('b-button',{staticClass:"mr-1",attrs:{"variant":"danger"},on:{"click":function($event){return _vm.$bvModal.hide('modal-1')}}},[_vm._v("Close")])],1)])],1)]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }