<template>
<div>
    <CRow>
        <CCol col>
        <CCard>
            <CCardHeader>
                <div class="row">
                    <div class="col-md-6">
                        <CIcon name="cil-justify-center"/><strong> Quizs</strong>
                    </div>
                    <div class="col-md-6">
                        <div class="text-right">
                            <button class="btn btn-primary" @click="editId = ''" v-b-modal.modal-1>Add New</button>
                        </div>
                    </div>
                </div>
            </CCardHeader>
            <CCardBody class="m-0">
                <div class="row p-0 mt-0">
                    <div class="col-md-12 m-0 p-0">
                        <div class="col-md-12 mt-0">
                            <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                <b-form  @submit.prevent="handleSubmit(searchData)" @reset.prevent="reset" >
                                    <div class="row mt-0">
                                        <div class="col-md-3">
                                            <b-form-group
                                                label-cols-sm="12"
                                                label-for="section_id"
                                            >
                                            <template v-slot:label>
                                            Section 
                                            </template>
                                            <Select2
                                             id="section_id"
                                                v-model="search.section_id"
                                                :options="sections"
                                            />
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-3">
                                                <b-form-group
                                                class="row"
                                                label-cols-sm="12"
                                                label-for="category_id"
                                                >
                                                <template v-slot:label>
                                                Select Category 
                                                </template>
                                                <Select2
                                                      id="category_id"
                                                      v-model="search.category_id"
                                                      :options="quizCategoryList"
                                                       />
                                                </b-form-group>
                                        </div>
                                        <div class="col-md-3">
                                                <b-form-group
                                                label-cols-sm="12"
                                                label-for="quiz_sub_category_id"
                                                >
                                                <template v-slot:label>
                                                Select Category 
                                                </template>
                                                <Select2
                                                      id="quiz_sub_category_id"
                                                      v-model="search.quiz_sub_category_id"
                                                      :options="quizSubCategoryList"
                                                       />
                                                </b-form-group>
                                        </div>
                                        <div class="col-md-3">
                                            <b-form-group
                                            label-cols-md="12"
                                            label-for="name"
                                            >
                                            <template v-slot:label>
                                                <span class="">Name</span>
                                            </template>
                                            <b-form-input
                                                id="name"
                                                v-model="search.name"
                                                class="form-control"
                                                ></b-form-input>
                                            </b-form-group>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="text-right">
                                                <b-button type="submit"  class="btn-font btn-sm" variant="primary"><i class="ri-search-line"></i> Search</b-button>
                                            </div>
                                        </div>
                                    </div>
                                </b-form>
                            </ValidationObserver>
                        </div>
                    </div>
                    <div class="col-md-12 mt-3 m-0">
                        <b-overlay :show='loading'>
                            <div class="overflow-auto">
                                <b-table thead-class="bg-light text-dark" class="table-sm" emptyText="Data Not Found" show-empty bordered hover :items="itemList" :fields="fields">
                                    <template v-slot:cell(index)="data">
                                        {{ $n(data.index + pagination.slOffset) }}
                                    </template>
                                    <template v-slot:cell(status)="data">
                                        <span class="badge badge-success" v-if="data.item.status == 1">Active</span>
                                        <span class="badge badge-danger" v-else>Inactive</span>
                                    </template>
                                    <template v-slot:cell(action)="data">
                                        <b-button v-if="data.item.status == 2" title="Change Status" class="ml-2 btn btn-success btn-sm" @click="changeStatus(data.item, 1)"><i class="ri-check-line"></i></b-button>
                                        <b-button v-else title="Change Status" class="ml-2 btn btn-danger btn-sm" @click="changeStatus(data.item, 2)"><i class="ri-close-line"></i></b-button>
                                        <b-button class="btn btn-success btn-sm ml-2" v-b-modal.modal-1 @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                                    </template>
                                </b-table>
                            </div>
                        </b-overlay>
                        <b-pagination
                        class="text-right"
                        v-model="pagination.currentPage"
                        :total-rows="pagination.total"
                        :per-page="pagination.perPage"
                        @input="searchData"
                        ></b-pagination>
                    </div>
                </div>
            </CCardBody>
        </CCard>
        </CCol>
    </CRow>
  <b-modal id="modal-1"
      size="lg"
    header-bg-variant="primary"
    header-text-variant="light"
      title="Entry/Edit" hide-footer>
    <div>
        <Form :id='editId'/>
  </div>
  </b-modal>
</div>
</template>
<script>
import RestApi, { baseUrl } from '../../config/api_config'
import { QUIZ } from '../../Utils/Constant';
import Form from './Form'
import iziToast from 'izitoast';

export default {
    components: {
        Form
    },
    created () {
        this.loadData ()
    },
    data() {
      return {
        search: {
            category_id: '',
            quiz_sub_category_id: '',
            name: '',
        },
        pagination: {
            perPage: 10,
            currentPage: 1,
            total: 0
        },
        editId: '',
        quizSubCategoryList: [],
        quizCategoryList: []
      }
    },
    computed: {
        sections () {
            const data = this.$store.state.commonObj.sectionList.filter(item => item.type_id == QUIZ)
            data.unshift({ 'id': 0, 'text': 'Select'})
            return data
        },
        itemList () {
            return this.$store.state.list
        },
        fields () {
            const labels = [
                { label: 'Sl No', class: 'text-center' },
                { label: 'Section', class: 'text-left' },
                { label: 'Category', class: 'text-left' },
                { label: 'Question', class: 'text-left' },
                { label: 'Sort', class: 'text-center' },
                { label: 'Status', class: 'text-center' },
                { label: 'Action', class: 'text-center' }
            ]

            let keys = []
            keys = [
            { key: 'id' },
            { key: 'section_name' },
            { key: 'category_name' },
            { key: 'title' },
            { key: 'sort' },
            { key: 'status' },
            { key: 'action' }
            ]
            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        loading () {
          return this.$store.state.static.loading
        },
        listReload () {
          return this.$store.state.static.listReload
        }
    },
    watch: {
        listReload: function (newVal) {
            if (newVal) {
                this.loadData()
            }
        },
        'search.section_id' : function (val) {
            this.getQuizCategoryList(val)
        },
        'search.category_id' : function (val) {
            this.getQuizSubCategoryList(val)
        }
    },
    methods: {
        getQuizSubCategoryList (catId) {
            const data = this.$store.state.commonObj.quizSubCategoryList.filter(item => item.quiz_category_id == catId)
            data.unshift({ 'id': 0, 'text': 'Select'})
            this.quizSubCategoryList = data
        },
        getQuizCategoryList (sectionId) {
            const data = this.$store.state.commonObj.quizCategoryList.filter(item => item.section_id == sectionId)
            data.unshift({ 'id': 0, 'text': 'Select'})
            this.quizCategoryList = data
        },
        changeStatus (item, status) {
            this.$swal({
                title: 'Are you sure to change status ?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                focusConfirm: false
            }).then((result) => {
                if (result.isConfirmed) {
                    this.toggleStatus(item, status)
                }
            })
        },
        toggleStatus (item, statusId) {
            RestApi.putData(baseUrl, `api/quizs/status/${item.id}`, { status: statusId }).then(response => {
                if (response.success) {
                    this.$store.dispatch('mutedLoad', { listReload: true })
                    iziToast.success({
                        title: 'Success',
                        message: response.message
                    })
                } else {
                    iziToast.error({
                        title: 'Success',
                        message: response.message
                    })
                }
            })
        },
        edit (item) {
            this.editId = item.id
        },
        searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
            this.$store.dispatch('mutedLoad', { loading: true})
            RestApi.getData(baseUrl, 'api/quizs/list', params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                }
                this.$store.dispatch('mutedLoad', { loading: false })
            })
        },
        paginationData (data) {
            this.pagination.perPage = parseInt(data.per_page)
            this.pagination.currentPage = parseInt(data.current_page)
            this.pagination.total = parseInt(data.total)
        }
    },
    filters: {
        subStr: function(string) {
            return string.substring(0, 80) + '...';
        }
    }
}
</script>